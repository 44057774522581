import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import OrderHeader from "../components/sections/OrderHeader"
import Footer from "../components/sections/footer"

const OrderPage = () => (
  <Layout>
    <SEO title="Order" />
    <Navigation />
    <OrderHeader />
    <Footer />
  </Layout>
)

export default OrderPage
